(function () {
    'use strict';

    class ExtranetApiSetupCtrl {
        constructor($state, $mdToast, $translate, $transition$, $q,configService) {
            this.state = $state;
            this.toast = $mdToast;
            this.$translate = $translate;

            this.$q = $q;
            this.configService = configService;
            this.httpMethods = [{value: "get", label: "GET"}, {value: "post", label: "POST"}, {
                value: "put",
                label: "PUT"
            }];

        }

        static get $inject() {
            return ["$state", "$mdToast", "$translate", "$transition$", "$q", "configService"];
        }

        $onInit() {
            this.secrets = {};
            this.simpleToast = this.toast.simple()
            .textContent(this.$translate.instant('exam_setup_edit_success'))
            .position("bottom right")
            .hideDelay(1500);
            this.config = {};
                        this.configService.getByHttp("healthcare_service_order_config")
                .then(data => {
                    this.configModel = data;
                });
        }

        submit() {
            // this.configService.set({healthcare_service_order_config: this.configModel})
            //     .then(() => this.toast.show(this.simpleToast));


            const promises = [
               this.configService.set({healthcare_service_order_config: this.configModel})
            ];

            if (!!this.secrets.password) {
                const passwordEvent = "shared.PasswordStore.set_config";
                const password = _.get(this.configModel.auth_credentials, "password")

                promises.push(this.configService.set({"healthcare_service_order_password": password}, false, null, passwordEvent));
            }

            this.$q.all(promises)
                .then(() => this.toast.show(this.simpleToast));

        }

    }

    module.exports = ExtranetApiSetupCtrl;

})()